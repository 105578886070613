import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { observable, Observable, Subscriber } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  socket: any;
  server = 'http://aprobadoya1.herokuapp.com:3000';
  constructor() {
    this.socket = io(this.server);
  }
  
  listen(eventName: string) {
    return new Observable((s) => {
      this.socket.on(eventName, (data) => {
        s.next(data);
      });
    });
  }
  emit(eventName: string, data: any) {
    this.socket.emit(eventName, data);
  }
}
