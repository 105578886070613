<div style="width= 40%; margin: auto; margin-top:100px;">
<div>
    <label for=""> Hola <span style="font-weight:bold;">{{userChat.user}}</span>... Escribe algo</label>
    <input type="text" class="form-control" (change)="myMessage()" [(ngModel)]="userChat.text">
</div>
<div class="mt-3">
    <div *ng-for="let a of myMessages">
<label for="" style="font-weight:bold;">{{a.user}}</label>
<span>{{a.text}}</span>
    </div>
</div>

</div>
