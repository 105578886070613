import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WebSocketService } from '../../services/web-socket.service';
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  userChat = {
    user: '',
    text: ''
  };
  myMessages;
  eventName = 'send-message';
  constructor(private Activated: ActivatedRoute, private webService: WebSocketService) { }

  ngOnInit(): void {
    const id = this.Activated.snapshot.params.id;
    this.userChat.user = id;
    this.webService.listen('text-event').subscribe((data) => {
      alert(data);
      this.myMessages = data;
    }

    );
  }
  myMessage() {
    alert( this.eventName +this.userChat.user+ this.userChat.text);
    this.webService.emit(this.eventName, this.userChat);
    this.userChat.text = '';
  }
}
